import { Technology as MyType } from '@shapeable/collaborate-ore-types';
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
import { EntityTypeName } from '@shapeable/types';
const gql = String.raw;

export const Innovation: EntityConfig<MyType> = {
  name: 'Innovation',
  type: 'standard',
  includeInSearch: true,

  list: {
    hasSearch: true,
    resultsVariant: 'grid',
  },
  autoRoutes: ['create', 'update', 'list', 'details'],
  form: {
    fields: {
      ...entityStandardFormFields,      
      trends: { type: 'linkedMultiple', entityTypeName: 'Trend' },
      topics: { type: 'linkedMultiple', entityTypeName: 'Topic' },
      subTopics: { type: 'linkedMultiple', entityTypeName: 'SubTopic' },
      description: { type: 'longText' },
      example: { type: 'longText' },
      horizons: { type: 'linkedTable', entityTypeName: 'Horizon' },
      technologies: { type: 'linkedMultiple', entityTypeName: 'Technology' },
      catalysts: { type: 'linkedMultiple', entityTypeName: 'Catalyst' },
      impactResultTypes: { type: 'linkedMultiple', label: 'Impacts', entityTypeName: 'ImpactResultType' },
      barriers: { type: 'linkedMultiple', entityTypeName: 'Barrier' },
      commodityGroups: { type: 'linkedMultiple', entityTypeName: 'CommodityGroup' },
      keyContacts: { type: 'linkedMultiple', entityTypeName: 'Person' },
      sdgs: { type: 'linkedMultiple', entityTypeName: 'Sdg' },

    }
  },
}; 