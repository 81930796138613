import { Commodity as MyType } from '@shapeable/collaborate-ore-types';
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
const gql = String.raw;

export const Commodity: EntityConfig<MyType> = {
  name: 'Commodity',
  type: 'taxonomy',
  list: {
    resultsVariant: 'list',
  },
  autoRoutes: ['create', 'update', 'list'],
  form: {
    fields: {
      ...entityStandardFormFields,
      description: { type: 'longText' },
      dataSetId: { type: 'text' }, // binds this record to the data set ID from the MSSQL database 
      alias: { type: 'text' },
      symbol: { type: 'text' },

    }
  },
}; 