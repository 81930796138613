import { Catalyst as MyType } from '@shapeable/collaborate-ore-types';
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
const gql = String.raw;
import { sortBy } from 'lodash';
export const Catalyst: EntityConfig<MyType> = {
  name: 'Catalyst',
  type: 'taxonomy',
  includeInSearch: true,
  list: {
    resultsVariant: 'list',
  },
  sidebar: {
    queryFields: 'outlineNumber',
    sort: (items) => sortBy(items, 'outlineNumber'),
    label: (catalyst) => `${catalyst.outlineNumber ? `${catalyst.outlineNumber}. ` : ''}${catalyst.name}`,
  },
  autoRoutes: ['create', 'update', 'list'],
  form: {
    fields: {
      ...entityStandardFormFields,
      outlineNumber: { type: 'text' },
      banner: { type: 'banner' },
      description: { type: 'longText' },
      exampleModel: { type: 'longText' },
    }
  },
}; 