import { Technology as MyType } from '@shapeable/collaborate-ore-types';
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
import { EntityTypeName } from '@shapeable/types';
const gql = String.raw;

export const Technology: EntityConfig<MyType> = {
  name: 'Technology',
  type: 'taxonomy',
  includeInSearch: true,
  list: {
    resultsVariant: 'list',
  },
  autoRoutes: ['create', 'update', 'list'],
  form: {
    fields: {
      ...entityStandardFormFields,      
      description: { type: 'longText' },
      banner: { type: 'banner' },
      sdgs: { type: 'linkedMultiple', entityTypeName: 'Sdg' },
    }
  },
}; 